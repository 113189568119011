.video-container {
    @include size (100%, 100%);
    position: relative;
    @include flexible(center, center);

    &_overlay {
        padding: 70px 0px 60px;
    }

    &_overlay:after {
        content: '';
        position: absolute;
        @include size (100%, 100%);
        background: #030d24;
        opacity: .7;
        top: 0;
    }

    video {
        @include size (100%, 100%);
        position: absolute;
        object-fit: cover;
        z-index: 0;
        top: 0;
    }

    &__text {
        text-align: center;
        z-index: 1;
        position: relative;
        padding: 0px 20px;
        @media screen and (max-width: $sm){
            max-width: 360px;
        }
    }

    &__logo {
        width: 517px;
        margin-bottom: 30px;
        animation: fadeIn 8s linear infinite;
        animation-delay: 0.3s;
        opacity: 0;
        @media screen and (max-width: $md){
            max-width: 80%;
            margin-bottom: 15px;
        }
    }

    &__btns {
        @include flexible (center, none);
        gap: 15px;
        padding-top: 130px;
        @media screen and (max-width: $md){
            padding-top: 50px;
        }
    }

    &__descr {
        text-align: left;
        margin-bottom: 50px;
    }

    &__items {
        @include flexible (space-around, none);
        @media screen and (max-width: $md){
            flex-direction: column;
        }
    }
}