.footer {
    background-color: $primary-color;
    &__inner {
        @include flexible(space-between, center);
        padding: 35px 0px;

        @media screen and (max-width: $md){
            justify-content: center;
        }
    }
    &__logo {
        width: 200px;
    }
    &__rights {
        font-size: 14px;
        @media screen and (max-width: $md){
            margin-top: 15px;
        }
        @media screen and (max-width: $xs){
            text-align: center;
        }
    }
}