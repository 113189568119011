@mixin size ($width, $height) {
    width: $width;
    height: $height;
}
@mixin styleForIcon ($color) {
    max-height: 100%;
    fill: $color;
    fill-rule: evenodd;
    stroke: $color;
    stroke-width: 0;
}

@mixin styleForText ($size, $color, $weight, $lineHeight) {
    @if ($size != none) {
        font-size: $size;
    }
    @if ($color != none) {
        color: $color;
    }
    @if ($weight != none) {
        font-weight: $weight;
    }
    @if ($lineHeight != none) {
        line-height: $lineHeight;
    }
}

@mixin flexible ($justifyContent, $alignItems) {
    display: flex;
    @if ($justifyContent != none) {
        justify-content: $justifyContent;
    }
    @if ($alignItems != none) {
        align-items: $alignItems;
    }
    @media screen  and (max-width: 1024px){
        flex-wrap: wrap;
    }
}