.contacts {
    &__inner {
        @include flexible (space-between, 'none');
    }
    &__map {
        width: 50%;
        iframe {
            max-width: 100%;
        }
        @media screen  and (max-width: $lg){
            margin-top: 20px;
            width: 100%;
        }
    }
    &__title.title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: normal;
    }
    &__name {
        @include styleForText (26px, none, none, 1.5);
        margin-bottom: 20px;
    }
    &__row {
        padding-bottom: 10px;
        border-top: 1px solid rgba(255, 255, 255, 0.1)
    }
}