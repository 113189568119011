.header {
    padding: 20px 0px 10px;

    @media screen and (max-width: $md){
        height: auto;
    }

    &__inner {
        @include flexible (space-between, center);

        @media screen and (max-width: $md){
            justify-content: center;
        }
    }
    &__menu {
        @media screen and (max-width: $md){
            width: 100%;
        }
        @media screen and (max-width: $xs){
            font-size: 12px;
        }
    }

    &__info {
        text-align: right;
        @media screen and (max-width: $md){
            margin-top: 10px;
        }
    }

    &__phone {
        @include styleForText (none, none, 700, 24px);
        display: flex;
        svg.icon {
            @include size (26px, 26px);
            margin-right: 10px;
        }
    }

    &__schedule {
        font-size: 14px;
    }
}

.menu {
    &__list {
        list-style-type: none;
        padding-inline-start: 0px;
        display: flex;
        gap: 12px;
        @media screen and (max-width: $md){
            justify-content: center;
            margin-block: 0px;
        }
    }

    &__link {
        position: relative;
    }

    &__link:after {
        content: '';
        position: absolute;
        display: block;
        bottom: -2px;
        left: 0;
        @include size(100%, 1px);
        background-color: $default-color;
        pointer-events: none;
        transform: translateY(2px);
        opacity: 0;
        transition: opacity .3s ease;
    }

    &__link:hover:after {
        transform: translateZ(0);
        opacity: 0.35;
    }
}