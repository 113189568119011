.slider-sertificates {
    &__slide {
        @include size (350px, 495px !important);
        overflow: hidden;
        cursor: pointer;
        &:hover img {
            transform: scale(1.06);
            transition: transform .5s ease;
        }
        a {
            display: block;
            height: 100%;
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            @media screen and (max-width: $lg){
                max-width: 80%;
            }
        }
        @media screen and (max-width: $xl){
            height: auto !important;
        }
        @media screen and (max-width: $lg){
            text-align: center;
        }
    }
}