// colors
$default-color: #fff;
$primary-color:  #030d24;
$dark-color: #060607;

// sizes
$xl: 1024px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 425px;