.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050;
    display: none;
    transition: opacity 400ms ease-in;
    pointer-events: none;
    &.show {
        @include flexible (center, center);
        opacity: 1;
        pointer-events: auto;
        overflow-y: auto;
    }
    &__dialog {
        position: relative;
        background-color: white;
        box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 0.3rem;
        max-width: 400px;
        padding: 40px;
        width: 100%;
        @media screen and (max-width: $md) {
            padding: 30px;
        }
    }
    &__body {
        color: $dark-color;
    }
    &__title {
        color: $dark-color;
        font-size: 22px;
        padding-bottom: 10px;
        text-align: center;
    }
    &-form {
        padding: 20px 0px 10px;
        &__field {
            margin-bottom: 20px;
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
        &__field.field-flex {
            display: flex;
            gap: 5px;
        }
        &__btn-submit {
            width: 100%;
            line-height: 46px;
            border-radius: 5px;
        }
        &__notification {
            display: none;
            margin: 10px 0px;
            text-align: center;
            font-weight: 500;
        }
        &__notification.show {
            display: block;
        }
    }
}