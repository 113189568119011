.card-solutions {
    max-width: 290px;
    padding-bottom: 30px;
    margin: auto;
    text-align: center;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(1.03);
    }
    &__img {
        margin-bottom: 20px;
        height: 203px;
        @media screen and (max-width: $md){
            height: 225px;
            object-fit: cover;
        }
    }

    &__title {
        @include styleForText (22px, none, 700, none);
        margin-bottom: 20px;
    }

    &__subtitle {
        font-size: 14px;
        margin-bottom: 20px;
    }

    &__btn {
        margin: 30px auto 0;
    }
}